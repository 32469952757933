/*

 A quick-and-dirty plugin for preventing duplicate submissions in forms.

 How to use:

 1. Add .js-prevent-double-submit to the submit button you want to control.
 2. Enjoy that selector being handled automatically.

 */

$(document).on("submit", "form.js-prevent-double-submit", function (event) {
    let is_submitting = $(this).data('is-submitting');
    if (is_submitting) {
        event.preventDefault();
    } else {
        $(this).data('is-submitting', true);
        $(this).find('button[type="submit"], input[type="submit"]').each(function () {
            $(this).prop('disabled', true);
            $(this).data('original-html', $(this).html());
            $(this).html('<i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>');
        });
    }
});

$(document).on("cancel-submit", "form.js-prevent-double-submit", function (event) {
    let is_submitting = $(this).data('is-submitting');
    if (is_submitting) {
        $(this).data('is-submitting', false);
        $(this).find('button[type="submit"], input[type="submit"]').each(function () {
            $(this).prop('disabled', false);
            $(this).html($(this).data('original-html'));
        });
    }
});
