/*

 A quick-and-dirty plugin for generating slugs in forms.

 How to use:

 1. Add data-slugify-selector=".whatever-selector"
 2. Enjoy that selector being updated automatically. If it's an input, val() will be set. If it's not, text() will be set.

 */

(function ($) {
    $("[data-slugify-selector]").on("change input", function () {
        "use strict";

        var $el = $($(this).data("slugify-selector"));
        var slugify = function slugify(text) {
            return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
        };
        var slug = slugify($(this).val());

        if ($el.is("input, textarea")) {
            $el.val(slug);
        } else {
            $el.text(slug);
        }
    });
})(jQuery);