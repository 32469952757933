let generateMultipleImagePlaceholder = (name) => {
    return `<div class="js-thumbnail js-placeholder">
                    <label class="thumbnail clickable">
                        <div class="js-placeholder">
                            <i class="fa fa-upload fa-4x"></i>
                        </div>
                        <input type="hidden" name="existing_${name}" value="">
                        <input type="hidden" name="${name}" value="">
                        <input type="file" class="hidden" name="${name}" />
                    </label>
                    <button type="button" class="btn btn-xs btn-danger js-remove"><span class="glyphicon glyphicon-trash"></span></button>
                </div>`;
}

$(document).on('ready', function () {
    $('.js-multiple-images').each(function () {
        let $this = $(this);
        /** @var {string[]} existing */
        let existing = $this.data('existing');

        $this.append(generateMultipleImagePlaceholder($this.data('name')));

        if (existing.length > 0) {
            existing.forEach(function (image) {
                $this.find('.js-placeholder:last').html(`<img src="${image}" class="img-responsive" />`);
                $this.find('.js-thumbnail:last').removeClass('js-placeholder');
                $this.find('[name="existing_' + $this.data('name') + '"]').val(image);
                $this.append(generateMultipleImagePlaceholder($this.data('name')));
            });

            $this.find('.js-remove').removeClass('hidden');
        }
    });
});

$(document).on('input', '.js-multiple-images [type="file"]', function () {
    let $this = $(this);
    let $thumbnail = $this.closest('.js-thumbnail');
    let $placeholder = $thumbnail.find('.js-placeholder');
    let file = $this[0].files[0];
    let reader = new FileReader();

    reader.onload = function (e) {
        $placeholder.html(`<img src="${e.target.result}" class="img-responsive" />`);
    }

    reader.readAsDataURL(file);

    $thumbnail.removeClass("js-placeholder");
    $this.closest('.js-multiple-images').append(generateMultipleImagePlaceholder($this.attr('name')));
});

$(document).on('click', '.js-multiple-images .js-remove', function () {
    $(this).closest('.js-thumbnail').fadeOut(300, function () {
        $(this).remove();
    });
});