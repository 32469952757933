(function () {
    /* Plugin for Redactor. */
    var redactor_options = {
        minHeight: 150,
        imageUpload: site_url("image_upload"),
        fileUpload: site_url("file_upload"),
        imageGetJson: site_url("image_library"),
        replaceDivs: false
    }, $wysiwyg = $('.wysiwyg');
    var $textareas = $("textarea");

    if ($wysiwyg.length > 0) {
        $wysiwyg.redactor(redactor_options);
    }

    if ($textareas.length > 0) {
        autosize($textareas);
        $textareas.on("focus", function () {
            "use strict";
            autosize($(this));
        });
    }

    $("select[multiple]:not(.select2)").chosen();
    $("select.chosen").chosen();

    /* Plugin for data tables. */
    $(".js-datatable").dataTable({
        "fnInitComplete": function (oSettings, json) {
            $(this).parents('.dataTables_wrapper').find('.dataTables_filter input').prop('placeholder', 'Search...').addClass('form-control input-sm');
        }
    });
})();

(function () {

    $('.image-popup-vertical-fit').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }

    });

    $('.image-popup-fit-width').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        image: {
            verticalFit: false
        }
    });

    $('.image-popup-no-margins').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos: true,
        mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        image: {
            verticalFit: true
        },
        zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
        }
    });


})();