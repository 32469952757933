/*

 A quick-and-dirty plugin for colouring radio button groups based on the selected value.

 No configuration is needed, just throw it in there.
 It will only have changes when the only options are yes or no. Anything else will not be coloured.

 If you need to customise the yes/no values, add data-value-yes and data-value-no to the radio-btn-group.
 Values can be comma-separated, if you want to support multiple yes/no values.

 */

function update_radio_btn_group($btn_group) {
    var $all_buttons = $btn_group.find('.btn');
    var $active_button = $all_buttons.find('input').filter(":checked").parents('.btn');
    var $inactive_buttons;
    var val;
    var btn_class = "secondary";
    var yes_values = [];
    var no_values = [];

    if ($btn_group.is('[data-value-yes]')) {
        yes_values = $btn_group.attr('data-value-yes').toLowerCase().split(',');
    } else {
        yes_values = ["1", "yes", "y"];
    }

    if ($btn_group.is('[data-value-no]')) {
        no_values = $btn_group.attr('data-value-no').toLowerCase().split(',');
    } else {
        no_values = ["", "0", "no", "n"];
    }

    if ($active_button.length > 0) {
        $inactive_buttons = $all_buttons.not($active_button);
        val = $active_button.find("input").val().toLowerCase();

        if (no_values.indexOf(val) !== -1) {
            btn_class = "danger";
        }

        if (yes_values.indexOf(val) !== -1) {
            btn_class = "success";
        }

        $active_button.removeClass("btn-default");
        $active_button.addClass("btn-" + btn_class);
        $inactive_buttons.removeClass("btn-danger btn-success btn-secondary btn-default").addClass("btn-default");
    } else {
        $all_buttons.removeClass("btn-danger btn-success btn-secondary btn-default").addClass("btn-default");
    }
}

$("body").on("change", ".radio-btn-group input", function () {
    update_radio_btn_group($(this).parents(".radio-btn-group"));
}).find(".radio-btn-group .btn").each(function () {
    update_radio_btn_group($(this).parents(".radio-btn-group"));
});