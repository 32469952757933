/*
 A quick-and-dirty plugin for adding "copy to clipboard" buttons to inputs/selects.

 How to use: Just add the class "js-copyable" to any input or select, and it'll get a copy button.
 Events: `copied` on the input, after something's been copied.
 */

function copyable() {
    let timeout = undefined;
    let $copyable = $('.js-copyable');
    let $copyButton = $('<span class="input-group-btn"><button data-toggle="tooltip" data-trigger="manual" data-placement="bottom" title="Copied!" type="button" class="btn btn-default"><i class="fa fa-copy" /></button></span>');

    $copyable.each(function () {
        let $this = $(this);
        let $button = $copyButton.clone();

        if (!$this.parent().hasClass('input-group')) {
            $this.wrap('<div class="input-group"></div>');
        }

        $this.after($button);

        $button.on('click', 'button', async function () {
            let $real_button = $(this);
            let value = $this.val();

            if (timeout) {
                clearTimeout(timeout);
            }

            // if it's a select, get the option's text
            if ($this.is('select')) {
                if ($this.hasClass('js-copy-value')) {
                    value = $this.val();
                } else {
                    value = $this.find('option:selected').text();
                }
            }

            await navigator.clipboard.writeText(value);
            $real_button.tooltip('show');
            $this.trigger('copied');
            timeout = setTimeout(function () {
                $real_button.tooltip('hide');
            }, 500);
        });
    });
}

copyable();