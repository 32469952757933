/*

 A quick-and-dirty plugin for toggling the time input in datepickers.

 How to use:

 1. Add the class 'date-time-switcher' to the desired input.
 2. Enjoy that input being updated automatically.

 */

function setup_date_time_switchers(selector) {
    $(selector ? selector : ".date-time-switcher").each(function () {
        var unique_name = 'date-time-switcher-' + Date.now();
        var current_active = $(this).hasClass("date-time-picker") ? 'date_and_time' : 'date';
        var template = '<div class="btn-group btn-group-justified radio-btn-group date-time-switcher-buttons js-date-time-switcher" data-toggle="buttons"><label class="btn btn-xs btn-default">Date Only <input name="' + unique_name + '" value="date" type="radio" autocomplete="off"></label><label class="btn btn-xs btn-default">Date and Time <input name="' + unique_name + '" type="radio" value="date_and_time" autocomplete="off"></label></div>';
        var $form_group = $(this).parents(".form-group");

        // If the buttons have not yet been added...
        if ($form_group.length > 0 && ($form_group.find(".js-date-time-switcher").length === 0)) {
            $form_group.children('div').prepend(template);
            update_radio_btn_group($form_group.find(".radio-btn-group .btn"));
            $form_group.find('.js-date-time-switcher [value="' + current_active + '"]').click();
        }
    });
}

$("body").on("change", ".js-date-time-switcher input", function () {
    var $el = $(this);
    var $form_group = $el.parents('.form-group');
    var $input = $form_group.find(".form-control");
    var val = $el.val();
    var datepicker_data;

    if (val === "date_and_time") {
        $input.removeClass("date-picker");
        $input.addClass("date-time-picker");
    } else {
        $input.removeClass("date-time-picker");
        $input.addClass("date-picker");
    }

    if (typeof $.fn.datetimepicker !== "undefined") {
        datepicker_data = $input.filter(".has-date-picker").data("DateTimePicker");
        if (datepicker_data) {
            datepicker_data.destroy();
        }
        $input.filter(".has-date-picker").removeClass("has-date-picker");
    }

    init_datepickers();
});

$(document).ready(function() {
    setup_date_time_switchers();
});