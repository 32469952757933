/* A quick-and-dirty plugin for starting up the date/time pickers, using Eonasdan's excellent Bootstrap 3 plugin. */

function init_datepickers() {
    $("input.date-time-picker, input.date-picker, input.datepicker").not(".has-date-picker").each(function () {
        "use strict";

        var include_time = $(this).is(".date-time-picker");
        var format = include_time ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
        var other_format = (include_time ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm");
        var html5_input_type = include_time ? 'datetime-local' : 'date';
        var val = $(this).val().split("-").join("/").trim();
        var extra_formats = [other_format, 'YYYY/MM/DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss', 'YYYY/MM/DD', 'YYYY/MM/DD HH:mm:ss', 'YYYY/MM/DDTHH:mm:ss', 'YYYY/MM/DDTHH:mm'];
        var m = moment(val, [format].concat(extra_formats), true);
        var options = {
            sideBySide: true,
            format: format,
            extraFormats: extra_formats,
            locale: 'en-gb',
            keepInvalid: !!$(this).is(".keep-invalid"),
            inline: !!$(this).is(".always-visible"),
            useCurrent: typeof ENV_USE_CURRENT_DATE_TIME_ON_CLICK !== "undefined" ? ENV_USE_CURRENT_DATE_TIME_ON_CLICK : true,
        };

        $(this).prop('autocomplete', 'nope');

        if ($(this).is(".always-visible")) {
            $(this).hide();
        }

        if ($(this).data("min-date")) {
            options.minDate = moment($(this).data("min-date"));
        }

        if ($(this).data("max-date")) {
            options.maxDate = moment($(this).data("max-date"));
        }

        const has_html5 = Modernizr.inputtypes[html5_input_type];
        const is_mobile = window.matchMedia('only screen and (max-device-width : 736px)').matches;
        const is_always_visible = $(this).is(".always-visible");

        if (has_html5 && is_mobile && !is_always_visible) {
            if (m.isValid()) {
                if (include_time) {
                    val = m.format("YYYY-MM-DDTHH:mm");
                } else {
                    val = m.format("YYYY-MM-DD");
                }
            } else {
                val = "";
            }
            $(this).val(val);
            $(this).attr("type", html5_input_type);
            $(this).attr("step", "any");
        } else {
            if (m.isValid()) {
                options.defaultDate = m;
                $(this).val("");
            } else {
                val = options.keepInvalid ? $(this).val() : "";
                $(this).val(val);
            }
            $(this).datetimepicker(options);
            $(this).addClass("has-date-picker");
        }
    });

    /* Plugin for time-picker. */
    $("input.time-picker, input.timepicker").not(".has-date-picker").each(function () {
        "use strict";

        var options = {
            format: "HH:mm",
            locale: 'en-gb',
            weekStart: 1,
        };

        if (Modernizr.inputtypes['time'] && window.matchMedia('only screen and (max-device-width : 736px)').matches) {
            $(this).val(moment($(this).val()).format("HH:mm"));
            $(this).attr("type", "time");
        } else {
            $(this).val(moment($(this).val().split("-").join("/")).format("HH:mm"));

            if ($(this).data("min-date")) {
                options.minDate = moment($(this).data("min-date"));
            }

            if ($(this).data("max-date")) {
                options.maxDate = moment($(this).data("max-date"));
            }

            $(this).datetimepicker(options);
            $(this).addClass("has-date-picker");
        }
    });
}

init_datepickers();
