var State = {}, Project = {}, UTIL = {};
var handle_form_sections = function () {
    var $form_sections = $(".js-form-section");
    var $submit = $('[type="submit"]');
    var $previous_container = $(".js-previous-container");
    var $next_container = $(".js-next-container");
    var $submit_container = $(".js-submit-container");
    var go_to_next = function () {
        var $current_section = $form_sections.filter(":visible");
        var $next = $current_section.nextAll('[data-show="true"]:first');

        if ($next.length > 0) {
            $current_section.fadeOut('fast', function () {
                $next.fadeIn('fast');
            });

            recalculate_buttons($next);
        }
    };
    var go_to_previous = function () {
        var $current_section = $form_sections.filter(":visible");
        var $prev = $current_section.prevAll('[data-show="true"]:first');

        if ($prev.length > 0) {
            $current_section.fadeOut('fast', function () {
                $prev.fadeIn('fast');
            });

            recalculate_buttons($prev);
        }
    };
    var recalculate_buttons = function ($current_section) {
        var has_next = $current_section.nextAll('[data-show="true"]:first').length > 0;
        var has_prev = $current_section.prevAll('[data-show="true"]:first').length > 0;

        if (has_next) {
            $next_container.show().find('.btn').prop('disabled', false);
            $submit_container.hide();
        } else {
            $next_container.hide().find('.btn').prop('disabled', true);
            $submit_container.show();
        }

        if (has_prev) {
            $previous_container.find('.btn').prop('disabled', false);
        } else {
            $previous_container.find('.btn').prop('disabled', true);
        }
    };
    var set_question = function (question_number, is_show) {
        $('[data-question="' + question_number + '"]').data('show', is_show).prop('data-show', is_show ? "true" : "false").attr('data-show', is_show ? "true" : "false");
        recalculate_buttons($form_sections.filter(":visible"));
    };

    $form_sections.on('change', '[type="radio"]', function () {
        var $el = $(this);
        var $parent = $el.parents(".btn");
        var $form_section = $el.parents(".js-form-section");
        var question = $form_section.data('question') + '';
        var answer = $el.val();

        if ($parent.is(".btn")) {
            $form_section.find(".btn").removeClass("active");
            $parent.addClass("active");
        }

        if (question === "1") {
            set_question(2, answer !== "No");
            set_question(3, answer !== "No");
        }

        if (question === "2") {
            set_question(3, answer !== "No");
        }

        if (question === "4") {
            set_question(5, answer !== "Yes");
        }

        if (!$el.data('has-further-questions')) {
            // There are no further questions, proceed to the next section.
            go_to_next();
        }
    });

    $previous_container.on('click', '.btn', go_to_previous);
    $next_container.on('click', '.btn', go_to_next);

    $form_sections.not(":first").hide();
    $previous_container.find('.btn').prop('disabled', true);
    $submit_container.hide();
    $submit.css('width', '120px').addClass("btn-lg").appendTo($submit_container);
};

function on_notification_click(client_form_id, notification_id) {
    const url = site_url("clients/edit_form/") + "/" + client_form_id;
    $.post(site_url("mark_as_read"), {
        notification_id: notification_id,
    })
        .done(function (data) {
            if (data == 'Ok') {
                window.location.href = url;
            }
        });
}

State = {};

Project = {
    common: {
        init: function () {

            // Ping the server every 60 seconds to keep the session alive.
            setInterval(function () {
                $.get('/ping');
            }, 60000);


            $('.js-items.js-reviews').on('item.added', '.js-item', function () {
                const $parent = $(this).parents('.js-items.js-reviews');
                let default_review_period = $parent.data('default-review-period');
                let max_date_timestamp = null;
                let default_timestamp;

                $parent.find('[name^="review_due_at"]').each(function () {
                    let value = $(this).val();

                    if (value) {
                        value = moment(value, "DD/MM/YYYY").format('X');
                        max_date_timestamp = Math.max(value, max_date_timestamp);
                    }
                });


                if (default_review_period) {
                    default_review_period = default_review_period.split(' ');
                    default_timestamp = max_date_timestamp ? moment(max_date_timestamp, "X") : moment();
                    $(this).find('[name^="review_due_at"]').val(default_timestamp.add(default_review_period[0], default_review_period[1]).format("DD/MM/YYYY"));
                }
            });

            if ($(".js-form-section:first").length > 0) {
                handle_form_sections();
            }

            $("select.select2").each(function () {
                let templateResult = $(this).data('custom-template-result');
                let options = {
                    theme: 'bootstrap',
                    id: '',
                    width: '100%',
                    placeholder: {
                        id: '',
                        text: $(this).find('option[value=""]').text()
                    }
                };

                if (templateResult) {
                    options.templateResult = eval(templateResult);
                }

                $(this).select2(options);
            });

            $("select.select2-tags").each(function () {
                $(this).select2({
                    theme: 'bootstrap',
                    tags: true,
                    width: '100%',
                    placeholder: {
                        id: '',
                        text: $(this).find('option[value=""]').text()
                    }
                })
            });

            // Forbid submitting the search box; use live autocomplete only.
            $(document).on('submit', 'form[role="search"]', function (event) {
                event.preventDefault();
            });

            // Enable Bootstrap's tooltips.
            $('[data-toggle="tooltip"]').tooltip();

            /* Plugin for triggering delete modals. */
            $(document).on('click', '.js-delete-record', function (event) {
                var template = $("#template-delete-modal").html();
                var $el = $(this);
                var id = 'delete' + Date.now();
                var selector = '#' + id;
                var singular = $el.data("singular");
                var human_value = $el.data("human-value");
                var primary_key = $el.data("row-primary-key");

                event.preventDefault();

                if (primary_key == "") {
                    throw new Error("No primary key was provided for deleting a record.");
                }

                if (singular == "") {
                    singular = "{{singular}}";
                }

                if (human_value == "") {
                    human_value = "{{human_value}}";
                }

                template = template.replace("{{primary_key}}", primary_key);
                template = template.replace("{{id}}", id);
                template = template.replace("{{url}}", $el.attr("href"));
                template = template.replace("{{singular}}", singular);
                template = template.replace("{{human_value}}", human_value);
                $("body").append(template);

                $(selector).on('hidden.bs.modal', function () {
                    $(selector).remove();
                }).modal('show');
            });

            $('.js-tops-table').on('click', '.js-zero-all', function (event) {
                event.preventDefault();
                $(this).parents('tr').find('.js-week-input').val("0").change();
            }).on('input change', '.js-week-input', function (event) {
                event.preventDefault();
                let $tr = $(this).parents('tr');
                let $inputs = $tr.find('.js-week-input');
                let $total = $tr.find('[name*="ndtms_total"]');
                let total = 0;
                let buffer = 0;
                for (let i = 0; i < $inputs.length; i++) {
                    buffer = parseInt($inputs.eq(i).val());
                    if (isNaN(buffer)) {
                        buffer = 0;
                    }
                    total += buffer;
                }
                $total.val(total);
            });

            // Trigger the change event on the first week's inputs to calculate the total.
            $('.js-tops-table tr [name*="week4"]').change();

            $(document).on('click', '.js-edit-case-note', function () {
                const note = $(this).data('note');
                const id = $(this).data('id');
                const is_quarantined = $(this).data('is-quarantined');
                const $modal = $('#edit-case-note-modal');

                if (is_quarantined) {
                    $modal.find('.is-quarantined').show();
                    $modal.find('.not-quarantined').hide();
                } else {
                    $modal.find('.is-quarantined').hide();
                    $modal.find('.not-quarantined').show();
                }

                $modal.find('#case_note_id_label').html(id);
                $modal.find('[name="case_note_id"]').val(id);
                $modal.find('#case_note_text').val(note);
            });

            $(document).on('click', '.js-view-case-note', function () {
                const $modal = $('#view-case-note-modal');
                const id = $(this).data('id');
                const url = site_url(`clients/case-notes/contents/${id}`);
                const $body = $modal.find('.modal-body');

                $modal.find('#case_note_id_label').html(id);
                $body.html('<div class="text-center" style="padding: 4em;"><i class="fa fa-spinner fa-spin fa-3x"></i></div>');
                $body.load(url, function () {
                    $modal.modal('show');
                });
            });

            $(document).on('submit', '#edit-case-note-modal form', async function (event) {
                event.preventDefault();

                const url = $(this).attr('action');
                const data = $(this).serializeArray();

                if (event.originalEvent.submitter) {
                    data.push({
                        name: 'submit',
                        value: $(event.originalEvent.submitter).val()
                    });
                }

                let response;

                try {
                    response = await $.post(url, data);
                } finally {
                    $('#edit-case-note-modal').modal('hide');
                    $('#edit-case-note-modal form').trigger('cancel-submit');
                }

                if (response.success) {
                    const $el = $('#case-note-' + response.row.id);
                    const $row = $el.parents('tr');

                    if (response.deleted) {
                        $row.remove();
                    } else {
                        $el.data('note', response.row.note);
                        $el.data('is-quarantined', response.row.quarantined_at !== null);
                        $('.casenote-content-' + response.row.id).html(response.content);
                    }
                }

            });
        },
        finalize: function () {

        }
    },
    clients: {
        view: function () {
            var get_currently_selected = function ($table) {
                var currently_selected = [];

                $table.find(".js-select-row-checkbox").each(function () {
                    var $el = $(this);
                    var row_id = $el.parents("tr").prop('id');
                    var is_checked = $el.prop('checked');

                    if (is_checked) {
                        currently_selected.push(row_id);
                    }
                });

                return currently_selected;
            };

            $(document).on('change', '.js-select-all-checkbox', function (event) {
                var $table = $(this).parents("table");
                var new_checked_value = (get_currently_selected($table).length === 0);
                $table.find(".js-select-all-checkbox, .js-select-row-checkbox").each(function () {
                    $(this).prop('checked', new_checked_value);
                });

                $table.trigger('row-selected', [get_currently_selected($table)]);
            });

            $(document).on('change', '.js-select-row-checkbox', function (event) {
                var $table = $(this).parents("table");
                $table.trigger('row-selected', [get_currently_selected($table)]);
            });

            $(document).on('click', '.js-select-cell', function (event) {
                var $table = $(this).parents("table");
                var $checkbox = $(this).find('.js-select-row-checkbox, .js-select-all-checkbox');
                var is_select_row = $(this).find('.js-select-row-checkbox').length > 0;

                if (!$(event.target).is('.js-select-row-checkbox, .js-select-all-checkbox') && $checkbox.length > 0) {
                    $checkbox.prop('checked', !$checkbox.prop('checked'));

                    if (is_select_row) {
                        $table.trigger('row-selected', [get_currently_selected($table)]);
                    } else {
                        $checkbox.trigger('change');
                    }
                }
            });

            $(".js-email-forms").hide();
            $('.js-select-table').on('row-selected', function (event, rows) {
                var form_names = [];
                var form_ids = [];
                var client_name = $(this).data('client-name');

                if (rows.length > 0) {
                    $.each(rows, function (key, value) {
                        form_names.push($('#' + value).find('.js-form-name').text());
                        form_ids.push(value.split('form-').join(''));
                    });

                    $(".js-email-forms").slideDown('fast');
                    $('.js-number-of-forms').text("Email " + rows.length + " form" + (rows.length === 1 ? '' : 's'));
                    $(".js-form-names").text(form_names.join(", "));
                    $('#email-multiple-forms').find('[name="form_ids"]').val(form_ids.join(','));
                    $('#email-multiple-forms').find('[name="subject"]').val(form_names.join(", ") + ' for ' + client_name);
                } else {
                    $(".js-email-forms").slideUp('fast');
                }
            });

            $('.js-new-medication').on('click', function (event) {
                var $modal = $('#medication-modal');
                event.preventDefault();
                $('#medication-modal form')[0].reset();
                $modal.find('[name="client_medication_id"]').val('').change();
                $modal.modal('show');
            });

            $('.js-edit-medication').on('click', function (event) {
                var data = $(this).data('medication');
                var $modal = $('#medication-modal');
                event.preventDefault();
                $modal.find('form')[0].reset();


                $modal.find('[name="client_medication_id"]').val(data.id).change();

                if (data.medication_id) {
                    $modal.find('[name="medication_id"]').val(data.medication_id).change();
                } else {
                    $modal.find('[name="medication_id"]').val('custom').change();
                    $modal.find('[name="custom_name"]').val(data.custom_name);
                }

                $modal.find('[name="date_prescribed"]').val(data.date_prescribed);
                $modal.find('[name="first_administration_time"]').val(data.first_administration_time);
                $modal.find('[name="estimated_end_date"]').val(data.estimated_end_date);
                $modal.find('[name="notes"]').val(data.notes);
                $modal.find('[name="measure"]').val(data.measure);
                $modal.find('[name="route_of_administration_code"]').val(data.route_of_administration_code);
                $modal.find('[name="is_prn"]').val(data.is_prn);
                $modal.find('[name="pathway"]').val(data.pathway);
                $modal.find('[name="is_ongoing"]').val(data.is_ongoing);
                $modal.find('[name="is_self_medicated"]').val(data.is_self_medicated);

                if (data.use_variable_dosage == 1) {
                    $('[name="use_variable_dosage"][value="1"]').click();

                    Object.keys(data.dosages).map(function (weekday) {
                        Object.keys(data.dosages[weekday]).map(function (time_of_day) {
                            $modal.find('[name="dosages[' + weekday + '][' + time_of_day + ']"]').val(data.dosages[weekday][time_of_day]);
                        });
                    });
                } else {
                    // if (typeof data.dosages !== 'undefined' && typeof data.dosages.monday !== 'undefined') {
                    // $modal.find('[name="breakfast"]').val(data.dosages.monday.breakfast);
                    // $modal.find('[name="lunch"]').val(data.dosages.monday.lunch);
                    // $modal.find('[name="dinner"]').val(data.dosages.monday.dinner);
                    // $modal.find('[name="evening"]').val(data.dosages.monday.evening);
                    // } else {
                    $modal.find('[name="breakfast"]').val(data.breakfast);
                    $modal.find('[name="lunch"]').val(data.lunch);
                    $modal.find('[name="dinner"]').val(data.dinner);
                    $modal.find('[name="evening"]').val(data.evening);
                    // }
                }

                $('#medication-modal').modal('show');
            });

            $('#medication_id, #client_medication_id').on('change input', function () {
                var medication_id = $('#medication_id').val();
                var client_medication_id = $('[name="client_medication_id"]').val();
                var $container = $('.js-hide-on-edit-and-not-custom');

                console.log(medication_id, client_medication_id);

                if (medication_id === 'custom') {
                    if (client_medication_id.trim() === '') {
                        $container.show();
                    } else {
                        $container.hide();
                    }
                } else {
                    $container.hide();
                }
            });

            $(document).on('change', '.js-select-form', function () {
                $(this).parents('form').find('button').prop('disabled', !$(this).val());
            });

            $(document).on('click', '.js-send-form', function () {
                console.log('sending', '#send-form-' + $('.js-select-form').val());
                $('#send-form-' + $('.js-select-form').val()).modal('show');
            });

            $('[name="treatment_stage"]').on('change', function () {
                let treatment_stage = $(this).val();

                if (typeof top_form_defaults !== "undefined" && typeof top_form_defaults[treatment_stage] !== 'undefined') {
                    if (typeof top_form_defaults[treatment_stage]['overall-quality-of-life'] !== 'undefined') {
                        $('[name="psychological_status"]').val(top_form_defaults[treatment_stage]['overall-quality-of-life']);
                    } else {
                        $('[name="psychological_status"]').val('');
                    }

                    if (typeof top_form_defaults[treatment_stage]['physical-health-status'] !== 'undefined') {
                        $('[name="physical_status"]').val(top_form_defaults[treatment_stage]['physical-health-status']);
                    } else {
                        $('[name="physical_status"]').val('');
                    }

                    if (typeof top_form_defaults[treatment_stage]['psychological-health-status'] !== 'undefined') {
                        $('[name="quality_of_life"]').val(top_form_defaults[treatment_stage]['psychological-health-status']);
                    } else {
                        $('[name="quality_of_life"]').val('');
                    }
                } else {
                    $('[name="psychological_status"]').val('');
                    $('[name="physical_status"]').val('');
                    $('[name="quality_of_life"]').val('');
                }
            });
        }
    },
    user_shifts: {
        calendar: function () {
            $('[data-target="#add-shift"]').on('click', function () {
                var $add_shift_modal = $("#add-shift");
                var date = $(this).data('date');
                var $date = $add_shift_modal.find('[name="date"]');

                $date.val(date);
            });
        }
    },
    pages: {
        init: function () {

        },
        login: function () {

        }
    }
};

window.Project = Project;

UTIL = {
    fire: function (func, funcname, args) {
        var namespace = Project;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function () {
        var bodyId = document.body.id;
        UTIL.fire('common');
        $.each(document.body.className.split(/\s+/), function (i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, bodyId);
        });
        UTIL.fire('common', 'finalize');
    }
};

UTIL.loadEvents();
