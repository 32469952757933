/*

 A quick-and-dirty plugin for firing an AJAX request.

 How to use:

 1. Add .fire-ajax to any link.
 2. You need a .refreshable-container-child in a .refreshable-container for it to work.
 3. Call update_page() if you want to update the page manually.

 */

(function ($) {
    update_page = function () {
        $('.refreshable-container').load(window.location.href + ' .refreshable-container-child', function () {
            $("#loading_modal").modal('hide');
            $("body").trigger("page_updated");
        });
    };

    $("body").on("click", ".fire-ajax", function (event) {
        $('.modal.in').modal('hide');
        $("#loading_modal").modal('show');

        $.get($(this).attr("href")).done(function (data) {
            if ((data + '').indexOf("#exception_error {") !== -1 || (data + '').indexOf("Unknown Error") !== -1) {
                $("#loading_modal").modal('hide');
                alert("An unknown error occurred. Please refresh and try again.");
            } else {
                update_page();
            }
        }).fail(function () {
            $("#loading_modal").modal('hide');
            alert("An unknown error occurred. Please refresh and try again.");
        });
        event.preventDefault();
    });

    window.update_page = update_page;
})(jQuery);