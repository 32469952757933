/**
 * Evaporate for S3
 *
 * Add .evaporate to any file input to make it upload directly to S3, with a progress bar.
 * Use "multiple" to allow selecting multiple files (all will be uploaded).
 *
 * This plugin depends on a few things being set:
 *     ENV_AWS_ACCESS_KEY_ID
 *     ENV_S3_UPLOADS_BUCKET
 *     ENV_IS_DEBUGGING
 *     ENV_S3_ENDPOINT
 *     ENV_S3_PREFIX
 *     site_url()
 *
 * It has a couple of per-input configurable options:
 *     data-evaporate-callback - Set it to be a valid callback if you want to be called when an upload finishes.
 *                               It's called with the S3 filename of the uploaded file.
 *     data-evaporate-acl - Set to public-read if you want the files to be publicly accessible in S3.
 *                          Default: private
 *
 * Events: uploaded.evaporate on the form, after all files have been uploaded.
 *
 */
(function ($) {
    var evaporate = new Evaporate({
        signerUrl: site_url("api/upload/signv4"),
        aws_key: ENV_AWS_ACCESS_KEY_ID,
        bucket: ENV_S3_UPLOADS_BUCKET,
        logging: ENV_IS_DEBUGGING,
        aws_url: ENV_S3_ENDPOINT,
        onlyRetryForSameFileName: true,
        awsRegion: ENV_AWS_REGION,
        computeContentMd5: true,
        cryptoMd5Method: (d) => btoa(SparkMD5.ArrayBuffer.hash(d, true)),
        // @ts-expect-error This is a valid option in the Evaporate docs.
        cryptoHexEncodedHash256: sha256,
    });
    var form_files_being_uploaded = 0;
    var uploaded_files = {};
    var uploaded_file_sizes = {};
    var files_being_uploaded = {};
    var ongoing_files = {};
    var calculate_progress = function (ongoing_files) {
        var sum = 0;
        var count = 0;
        var done = 0;

        $.each(ongoing_files, function (key, value) {
            count++;
            if (value == 101) {
                done++;
                value = 100;
            }
            sum += value;
        });

        if (done == count) {
            return 101;
        } else {
            return ((sum / (count * 100)) * 100).toFixed(2) * 1;
        }
    };
    var update_progress = function (ongoing_files, $parent) {
        var progress = calculate_progress(ongoing_files);
        var label = progress + '%';

        if (progress === 101) {
            label = 'Done!';
            progress = 100;
        }

        var progress_template = '<div class="evaporate-progress progress"><div class="progress-bar" role="progressbar" aria-valuenow="' + progress + '" aria-valuemin="0" aria-valuemax="100" style="min-width: 2em; width: ' + progress + '%;">' + label + '</div></div>';

        if ($parent.find(".progress").length === 0) {
            $parent.append(progress_template);
        }

        $parent.find(".progress-bar").attr("aria-valuenow", progress).html(label).width(progress + "%");
    };
    var evaporate_add = function (input_name, $form, $input, $fileinput, $parent, evaporate_callback, acl, filename, file) {
        var ext = file.name.split(".").pop();
        var final_filename = filename.split(".document").join("") + "." + ext;
        var s3_key = ENV_S3_PREFIX + final_filename;
        var name_parts = input_name.split("[");
        var sub_slices = name_parts.slice(1);
        var hidden_input_name = "evaporate[" + name_parts[0] + "]" + (sub_slices.length > 0 ? ('[' + sub_slices.join('[')) : '');
        var $hidden_input = $form.find('[name="' + hidden_input_name + '"]');
        var hidden_sizes_input_name = "evaporate_image_sizes[" + name_parts[0] + "]" + name_parts.slice(1).join("[");
        var $hidden_sizes_input = $form.find('[name="' + hidden_input_name + '"]');

        if ($hidden_input.length === 0) {
            $form.append('<input type="hidden" name="' + hidden_input_name + '">');
            $hidden_input = $form.find('[name="' + hidden_input_name + '"]');
        }

        if ($hidden_sizes_input.length === 0) {
            $form.append('<input type="hidden" name="' + hidden_sizes_input_name + '">');
            $hidden_sizes_input = $form.find('[name="' + hidden_sizes_input_name + '"]');
        }

        if (typeof uploaded_files[hidden_input_name] === "undefined") {
            uploaded_files[hidden_input_name] = {};
        }

        if (typeof uploaded_file_sizes[hidden_input_name] === "undefined") {
            uploaded_file_sizes[hidden_input_name] = {};
        }

        if (typeof ongoing_files[hidden_input_name] === "undefined") {
            ongoing_files[hidden_input_name] = {};
        }

        if (typeof files_being_uploaded[hidden_input_name] === "undefined") {
            files_being_uploaded[hidden_input_name] = 0;
        }

        form_files_being_uploaded++;
        files_being_uploaded[hidden_input_name]++;
        ongoing_files[hidden_input_name][s3_key] = 0;
        update_progress(ongoing_files[hidden_input_name], $parent);
        evaporate.add({
            name: s3_key,
            file: file,
            xAmzHeadersAtInitiate: {'x-amz-acl': acl},
            complete: function () {
                var details = {
                    "s3_filename": final_filename,
                    "original_filename": file.name
                }, url, img;

                if (["jpg", "jpeg", "gif", "png", "bmp", "eps", "ai", "svg"].indexOf(ext) !== -1) {
                    url = URL.createObjectURL(file);
                    img = new Image;
                    img.onload = function () {
                        uploaded_file_sizes[hidden_input_name][final_filename] = {
                            width: img.width,
                            height: img.height
                        };
                        $hidden_sizes_input.val(JSON.stringify(uploaded_file_sizes[hidden_input_name]));
                    };
                    img.src = url;
                }

                ongoing_files[hidden_input_name][s3_key] = 101;
                uploaded_files[hidden_input_name][s3_key] = details;

                $hidden_input.val(JSON.stringify(uploaded_files[hidden_input_name]));

                update_progress(ongoing_files[hidden_input_name], $parent);
                form_files_being_uploaded--;
                files_being_uploaded[hidden_input_name]--;

                // Remove the ongoing file; it's no longer ongoing.
                // This prevents an issue where uploading another file with the same input would lead to the progress bar being wrong.
                delete ongoing_files[hidden_input_name];

                if (files_being_uploaded[hidden_input_name] === 0) {
                    $form.trigger('uploaded.evaporate', details.s3_filename);
                }

                evaporate_callback = $input.data("evaporate-callback");
                if (typeof (evaporate_callback) === "function") {
                    evaporate_callback.apply($input, [details.s3_filename]);
                } else if (typeof (evaporate_callback) === "string") {
                    window[evaporate_callback](details.s3_filename);
                } else if (typeof (evaporate_callback) !== "undefined") {
                    throw new Error("evaporate_callback - " + evaporate_callback + " is not a function, it's a " + typeof (evaporate_callback) + ".");
                }
            },
            progress: function (progress) {
                if (typeof ongoing_files[hidden_input_name] !== "undefined") {
                    if (ongoing_files[hidden_input_name][s3_key] != 101) {
                        ongoing_files[hidden_input_name][s3_key] = progress * 100;
                        update_progress(ongoing_files[hidden_input_name], $parent);
                    }
                }
            }
        });
    };
    var handle_input = function (event) {
        var $input = $(this);
        var $form = $input.parents("form");
        var $fileinput = $input.parents(".fileinput");
        var $parent = ($fileinput.length === 0) ? $input.parent() : $fileinput.parent();
        var acl = $input.data("evaporate-acl") ? $input.data("evaporate-acl") : "private";

        $.each(event.target.files, function (key, file) {
            $.getJSON(site_url("get_s3_filename"), {random: Date.now()}, function (data) {
                evaporate_add($input.prop('name'), $form, $input, $fileinput, $parent, $input.data("evaporate-callback"), acl, data.new_filename, file);
            }).fail(function () {
                alert("Something went wrong while trying to upload this file. Refresh and try again.");
            });
        });

        $input.val('').hide();
        $fileinput.hide();
        $parent.find(".help-block").hide();
    };
    var handle_submit = function (event) {
        var wording = 'are {x} files';

        if (form_files_being_uploaded == 1) {
            wording = 'is a file';
        }

        wording = wording.replace('{x}', form_files_being_uploaded);
        if (form_files_being_uploaded > 0) {
            alert("You cannot submit this form because there " + wording + " still being uploaded.");
            event.preventDefault();
        }
    };

    $("body")
        .on("change", 'form .evaporate', handle_input)
        .on("submit", 'form', handle_submit);
})(jQuery);