/*

 A quick-and-dirty plugin for loading modal content via AJAX.

 How to use:

 1. Add data-toggle="modal" href="#arbitrary-modal" data-modal-title="test title" data-modal-content-url="http://url.to/modal-content"
 2. Enjoy automatic loading spinner icon and loading remote content.

 */

(function ($) {
    "use strict";
    var $links_to_edit = $("[data-modal-content-url]");
    var default_content = '<div class="text-center" style="padding: 6em;"><span class="fa fa-5x fa-spinner fa-spin fa-fw"></span><h4 style="margin-top: 1em;">Loading, please wait...</h4></div>';

    if ($links_to_edit.length > 0) {
        if ($('#arbitrary-modal').length === 0) {
            $('body').append('<div class="modal fade" role="dialog" id="arbitrary-modal">\n' +
                '        <div class="modal-dialog modal-lg">\n' +
                '            <div class="modal-content">\n' +
                '                <div class="modal-header">\n' +
                '                    <h4 class="modal-title"></h4>\n' +
                '                </div>\n' +
                '                <div class="modal-body">\n' +
                '                </div>\n' +
                '                <div class="modal-footer">\n' +
                '                    <a data-dismiss="modal" class="btn btn-default">Close</a>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </div>\n' +
                '    </div>');
        }
    }

    $links_to_edit.on("click", function () {
        var title = $(this).data('modal-title');

        if (title) {
            $('#arbitrary-modal .modal-title').html(title);
        } else {
            $('#arbitrary-modal .modal-header').remove();
        }
        $('#arbitrary-modal .modal-body').html(default_content);
        $.get($(this).data('modal-content-url')).then(function (html) {
            $('#arbitrary-modal .modal-body').html(html);
        });
    });
})(jQuery);