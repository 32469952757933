$(document).on('click', '.js-calculate-age + .input-group-addon', function () {
    let $input = $(this).parent().find(".js-calculate-age");
    $input.focus();
});

$(document).on("change input dp.change", ".js-calculate-age", function () {
    let age = moment().diff(moment($(this).val(), ['DD/MM/YYYY', 'YYYY-MM-DD']), 'years', false);

    if (isNaN(age) || age > 99) {
        age = '<span class="glyphicon glyphicon-calendar"></span>';
    }

    const $suffix = $(this).parent().find(".input-group-addon");

    if (!$suffix.length) {
        console.log(this);
        throw new Error("No 'suffix' found for .js-calculate-age.");
    }

    $suffix.html(age);
});

$(document).ready(function () {
    $(".js-calculate-age").trigger("change");
});