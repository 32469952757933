/*

 A quick-and-dirty plugin for live filters that change the table's contents on demand.

 It makes use of the "filters" in crud/index.

 */

(function () {
    var is_refreshing = false;
    var refresh_crud_table = function (event, custom_url) {
        var data = {};
        var $filters_row = $(".js-filters-row");
        var $table_container = $(".table-container");
        var $datatable = $(".dataTable-helper.ui-datatable");
        var per_page = $filters_row.data('per-page');
        var start_offset = $filters_row.data('start-offset');
        var uri_string = window.location.href.split(site_url("")).join("").split("#").join("").split(per_page + "/" + start_offset).join(per_page + "/0").split("?")[0];
        var get_q = function() {
            var regex = new RegExp("[?&]q(=([^&#]*)|&|#|$)");
            var results;

            if (window.location.search == "") {
                return "";
            }

            results = regex.exec(window.location.search);

            if (!results) {
                return "";
            }

            if (typeof results[2] === "undefined") {
                return "";
            }

            if (!results[2]) {
                return "";
            }

            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };
        var q = get_q();
        var scroll_to = $filters_row.offset().top + $filters_row.height();
        var current_scroll_position = $(window).scrollTop();
        var url;
        var $target = ((typeof event === "undefined") || (typeof event.target === "undefined")) ? null : $(event.target);
        var all_columns = [];
        var selected_columns;

        if (q) {
            data["q"] = q;
        }

        if (event) {
            event.preventDefault();
        }

        $filters_row.find("select, input").each(function () {
            var $el = $(this);
            var field_name = $el.attr("name");
            var val;
            if (field_name) {
                if ($el.prop('type') == "radio") {
                    val = $('[name="' + $el.prop('name') + '"]:checked').val();
                } else {
                    val = $el.val();
                }

                if (val == "" || val === null || !val) {
                    val = "all";
                }

                data[field_name] = val;
            }
        });

        url = (custom_url ? custom_url : (site_url(uri_string) + "?" + $.param(data)));

        if ($table_container.length > 0) {
            if (!is_refreshing) {
                $table_container.height($table_container.height());
                $table_container.html('<h2 class="text-center" style="margin-top: 4em;"><i class="fa fa-spinner fa-spin fa-2x" id="indicator"></i><br /><br />Updating...</h2>');
                if (current_scroll_position > scroll_to) {
                    $(window).scrollTop(scroll_to);
                }
            }

            $table_container.load(url + " .table-container > .table-sub-container", function () {
                $table_container.css('height', 'auto');
                $table_container.trigger('table_updated');
                is_refreshing = false;
            });
        }

        if ($datatable.length > 0) {
            $datatable = $datatable.DataTable();

            if ($target && $target.is('[name="show_columns"]')) {
                $target.find('option').each(function() {
                    all_columns.push($(this).prop('value'));
                });

                selected_columns = $(event.target).val();

                $.each(all_columns, function (key, value) {
                    var column = $datatable.column(key);

                    if (selected_columns && selected_columns.indexOf(value) === -1) {
                        if (column.visible()) {
                            column.visible(false);
                        }
                    } else {
                        if (!column.visible()) {
                            column.visible(true);
                        }
                    }
                });
            } else {
                $datatable.draw();
            }
        }

        if ('history' in window && 'pushState' in history) {
            if (url.length < 4096) {
                history.replaceState(null, null, url);
            }
        }
    }

    $(document).ready(function() {
        if ($(".js-filters-row").length > 0) {
            $(".js-filters-row").find("input, select").on("input change dp.change", function (event) {
                refresh_crud_table(event);
            });

            $(document).on("click", ".pagination li a", function (event) {
                var $li = $(this).parents("li");
                var is_datatable = ($li.parents('.datatable-container').length > 0);

                if (is_datatable) {
                    // Let datatables handle it.
                    return;
                }

                if ($li.hasClass("disabled") || $li.hasClass("active")) {
                    event.preventDefault();
                } else {
                    $(".pagination li").addClass("disabled");
                    refresh_crud_table(event, $(this).attr("href"));
                }
            });

            setInterval(function () {
                is_refreshing = true;
                refresh_crud_table();
            }, 60 * 1000);
        }
    });

    window.refresh_crud_table = refresh_crud_table;
})();