/*

 A quick-and-dirty plugin for imploding an array to a human CSV (e.g. commas followed by an "and" as the last item).

 How to use:

 implode_to_human_csv(array)

 */

(function () {
    implode_to_human_csv = function (array) {
        var separator = ", ";
        var string = array.join(separator);
        var last_index_of = string.lastIndexOf(separator);
        if (last_index_of >= 0) {
            string = string.substring(0, last_index_of) + " and " + string.substring(last_index_of + separator.length);
        }

        return string;
    };

    window.implode_to_human_csv = implode_to_human_csv;
})();