/*

 A quick-and-dirty Bootstrap 3 plugin for adding/removing items with JS.

 How to use:

 1. Create a container div with class "js-items" (plus anything else you want to uniquely identify it, like id="medications").
 2. Create an <a> or <button> with class "js-add-item" and data-target="#medications" (or whatever you used above).
 3. Add each existing item to the container div, with each item having class "js-item".

 That's it! Your items will be dynamically added/removed now.

 Events: item.added on the new item, after a new item has been created.
         item.removed on the item, right before it's removed.

 */

(function ($) {
    var display_remove_icons = function (container, adjustment) {
        var $container = isNaN(container) ? $(container) : $(this);
        var item_count = $container.find(".js-item").length;

        if (!isNaN(parseInt(adjustment))) {
            item_count += adjustment;
        }

        if (item_count > 1) {
            $container.find(".js-remove-item").fadeIn();
        } else {
            $container.find(".js-remove-item").fadeOut();
        }
    };

    $(".js-items").on("click", ".js-item .js-remove-item", function (event) {
        var $el = $(this);
        var $item = $el.parents(".js-item");
        var $container = $item.parents(".js-items");
        var item_count = $container.find(".js-item").length;
        let target;

        event.preventDefault();

        if (item_count > 1) {
            $container[0].classList.forEach(function (value) {
                let $el = $('.js-add-item[data-target=".' + value + '"]');

                if ($el.length > 0) {
                    target = '.' + value;
                }
            });

            $item.trigger("item.removed", {
                i: $item.data('i'),
                target: target
            });

            $item.slideUp('fast', function () {
                $(this).remove();
            });

            display_remove_icons($container, -1);
        } else {
            alert("You can't remove the last item!");
        }
    }).each(display_remove_icons);

    $(".js-add-item").on("click", function (event) {
        var $el = $(this);
        var target = $el.data("target");
        var $container = $(target);
        var $items = $container.find(".js-item");
        var $first = $items.first();
        var $clone;
        var datepicker_data;

        event.preventDefault();

        if (typeof $.fn.select2 !== "undefined") {
            $first.find("select.select2, select.tokenize, select.select2-tags").select2('destroy').attr('data-select2-id', null);
            $first.find("select.select2 option, select.tokenize option, select.select2-tags option").attr('data-select2-id', null);
        }

        if (typeof $.fn.datetimepicker !== "undefined") {
            datepicker_data = $first.find(".has-date-picker").data("DateTimePicker");
            if (datepicker_data) {
                datepicker_data.destroy();
            }
            $first.find(".has-date-picker").removeClass("has-date-picker");
        }

        $clone = $first.clone();

        $clone.find(".sigPad").removeClass("has-sigpad");
        $clone.find(".sigPad").data("editable", true);

        $clone.find(".sigPad .sigWrapper input").val("");
       
        $clone.find(".js-img-container").html("");

        $clone.find(".btn.active.btn-secondary").removeClass("active btn-secondary").addClass("btn-default");
        $clone.find(".btn.active").removeClass("active");

        $clone.find(".evaporate").each(function () {
            var $input = $(this);
            var $fileinput = $input.parents(".fileinput");
            var $parent = ($fileinput.length === 0) ? $input.parent() : $fileinput.parent();

            $input.val('').show();
            $fileinput.show();
            $parent.find(".help-block").show();
            $parent.find(".evaporate-progress").remove();
        });

        $clone.find("select, input, textarea").each(function () {
            var old_name = $(this).prop("name");
            var new_name = old_name.replace(/\[[0-9]+\]/g, "[" + ($items.length) + "]");
            var old_id = $(this).prop("id");
            var new_id = old_id.replace(/_[0-9]+_/g, "_" + ($items.length) + "_");

            $(this).prop("name", new_name);
            $(this).prop("id", new_id);
            $(this).removeClass('has-loaded-offline-value');
        });
        $clone.find("option").removeProp("selected");
        $clone.find('[type="checkbox"], [type="radio"]').prop('checked', false);
        $clone.find("input, textarea").not('[type="hidden"], [type="checkbox"], [type="radio"]').val('');
        $container.append($clone);
        display_remove_icons($container);

        $clone.find('.js-remove-on-add-item').remove();

        if (typeof $.fn.select2 !== "undefined") {
            $("select.select2").each(function () {
                let templateResult = $(this).data('custom-template-result');
                let options = {
                    theme: 'bootstrap',
                    id: '',
                    width: '100%',
                    placeholder: {
                        id: '',
                        text: $(this).find('option[value=""]').text()
                    }
                };

                if (templateResult) {
                    options.templateResult = eval(templateResult);
                }

                $(this).select2(options);
            });

            $("select.tokenize, select.select2-tags").each(function () {
                $(this).select2({
                    theme: 'bootstrap',
                    tags: true,
                    width: '100%',
                    placeholder: {
                        id: '',
                        text: $(this).find('option[value=""]').text()
                    }
                });
            });
        }

        if (typeof ($.fn.datetimepicker) !== "undefined" && typeof (init_datepickers) !== "undefined") {
            init_datepickers();
        }

        if (typeof (refresh_sigpads) !== "undefined") {
            refresh_sigpads();
        }

        if (typeof (autosize) !== "undefined") {
            autosize($clone.find('textarea'));
            $clone.find('textarea').on("focus", function () {
                "use strict";
                autosize($(this));
            });
        }

        $clone.data('i', $items.length);
        $clone.find(".radio-btn-group").each(function () {
            var $el = $(this);
            var $all_buttons = $el.find(".btn");
            var $inputs = $el.find("input");
            var default_val;
            var $input;

            $inputs.each(function () {
                var default_data = $(this).data('default');
                if (typeof default_data !== "undefined") {
                    default_val = default_data;
                }
            });

            if (typeof default_val !== "undefined") {
                $input = $inputs.filter('[value="' + default_val + '"]');
                $input.prop('checked', true);
                $input.parents('.btn').addClass('active');
            }

            update_radio_btn_group($all_buttons);
        });

        $clone.trigger("item.added", {
            i: $items.length,
            target: target
        });
    });
})(jQuery);
